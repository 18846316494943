import React, {useEffect, useState} from 'react';
import type { FormProps } from 'antd';
import { Button, Checkbox, Flex, Form, Input } from 'antd';
import css from './Login.module.css'
import {setAuthData, setAuthStatus} from "../../../redux/authReducer";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ShowError from "../../ShowError/ShowError";
// import {setAuthStatusThunkCreator} from "../../../redux/authReducer";



type FieldType = {
    email?: string;
    password?: string;
    remember?: string;
};


const Login: React.FC = (props:any) => {

    // let [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        if(props.auth.authStatus){
            if(props.app.activeMenuTab !== '' && props.app.activeMenuTab !== '/login'){
                navigate(props.app.activeMenuTab);
            }else{
                navigate('/');
            }
        }
    },[props.auth.authStatus])

    const { t } = useTranslation('',{ keyPrefix: 'login' });

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);
        props.setLoading(true);
        props.setAuthData(values.email, values.password, values.remember);
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    // debugger
    return props.auth.authStatus ? <>logged in</> : <div className={css.form_wrapper}>
        <h1 className={'center'}>{t("Authorization")}</h1>
        <Form className={`m_auto center`}
              name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
              style={{maxWidth: 400}}
              initialValues={{
                  remember: true,
                  email: "test@admin.com",
                  password: "myPassword12"

              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              // theme="dark"
        >
            <ShowError errors={[props.auth.error !== '' ? t(props.auth.error) : '']} />
            <Form.Item<FieldType>
                label={t("Email")}
                name="email"
                rules={[{required: true,}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item<FieldType>
                label={t("Password")}
                name="password"
                rules={[{required: true, message: 'Please input your password!'}]}
            >
                <Input.Password/>
            </Form.Item>

            <Form.Item<FieldType>
                name="remember"
                valuePropName="checked"
                // wrapperCol={{ offset: 8, span: 16 }}
            >
                <Checkbox>{t("Remember me")}</Checkbox>
            </Form.Item>

            <Form.Item
                // wrapperCol={{ offset: 8, span: 16 }}
            >
                <Button type="primary" htmlType="submit" loading={props.auth.loading} disabled={props.auth.loading}>
                    {t("Enter")}
                </Button>
            </Form.Item>
        </Form>
    </div>
};


// const Login1 = () => {
//     return (
//         <div>
//             <h1>Login Page</h1>
//             <p>authorization will be here soon...</p>
//             <Button>test</Button>
//             <Button type="primary">test</Button>
//         </div>
//     );
// };

export default Login;
