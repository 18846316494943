import React from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import SideMenuContainer from "../SideMenu/SideMenuContainer";
import {Link, Route, Routes} from "react-router-dom";
import Home from "../Home/Home";
import LoginContainer from "../LoginContainer/LoginContainer";
import SignUp from "../SignUp/SignUp";
import Clients from "../Clients/Clients";
import UsersContainer from "../Users/UsersContainer";
import AddUserContainer from "../AddUser/AddUserContainer";
import logo from '../../imgs/myCRM_logo.png'
import css from './Main.module.css'
import {compose} from "redux";
import {connect} from "react-redux";
import {setAuthData, setLoading} from "../../redux/authReducer";
import ClientsContainer from '../Clients/ClientsContainer';
import AddClient from "../AddClient/AddClient";
import AddClientContainer from "../AddClient/AddClientContainer";
import AddCampaignContainer from "../AddCampaignContainer/AddCampaignContainer";
import CampaignsContainer from "../Campaigns/CampaignsContainer";
import ClientViewContainer from "../ClientView/ClientViewContainer";

const { Header, Content, Footer, Sider } = Layout;


const Main = (props:any) => {
    return (

        // <Row>
        //     <Col span={4} className={"sideMenu"}>
        //
        //     </Col>
        //     <Col span={19}>
        //     </Col>
        // </Row>


    <Layout>
        <Header style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="" className={css.mainLogo}/>
            <div className="demo-logo" />
            {/*<Menu*/}
            {/*    theme="dark"*/}
            {/*    mode="horizontal"*/}
            {/*    defaultSelectedKeys={['2']}*/}
            {/*    items={items1}*/}
            {/*    style={{ flex: 1, minWidth: 0 }}*/}
            {/*/>*/}
        </Header>
        <Content style={{  }}>
            {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
            {/*    <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item>List</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item>App</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            <Layout
                style={{   }}
            >
                <Sider style={{}} className={css.sider} width={props.auth.authStatus ? undefined : 0}
                       // collapsible
                >
                    <SideMenuContainer/>
                </Sider>
                <Content style={{ padding: '0 24px', minHeight: '80vh' }}>

                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/login" element={<LoginContainer/>}/>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/clients" element={<ClientsContainer/>}/>
                        <Route path="/addClient" element={<AddClientContainer/>}/>
                        <Route path="/users" element={<UsersContainer/>}/>
                        <Route path="/addUser" element={<AddUserContainer/>}/>
                        <Route path="/addCampaign" element={<AddCampaignContainer/>}/>
                        <Route path="/campaigns" element={<CampaignsContainer/>}/>
                        <Route path="/brands" element={<CampaignsContainer/>}/>
                        <Route path="/desks" element={<CampaignsContainer/>}/>
                        <Route path="/client/:id" element={<ClientViewContainer/>}/>
                    </Routes>
                </Content>
            </Layout>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
            myCRM ©{new Date().getFullYear()} Created by Andrew aka <Link to="https://t.me/iamadmin111">@iamadmin111</Link>
        </Footer>
    </Layout>

    );
};




export default Main;
