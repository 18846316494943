export const RU = {
    "":"",
    "addUser": {
        "Login": "Пожалуйста введите логин нового пользователя",
        "login_error": "Введите логин!",
        "Add new user": "Добавить нового пользователя",
        "User Added Successfully!": "Пользователь успешно добавлен!",
        "We advice you to setup this user's permissions": "Мы рекомендуем вам настроить разрешения этого пользователя",
        "Set Permissions": "Установить разрешения",
        "Go to users list": "Перейти к списку пользователей",
        "Close/Add One More User": "Закрыть/добавить еще одного пользователя",
        "Email": "Email",
        "Password": "Пароль",
        "Full Name": "Полное имя",
        // "Last Name": "Фамилия",
        "Active": "Активный",
        "Desk": "Деск",
        "Role": "Роль",
        "Brand": "Бренд",
        "Supervisor": "Руководитель",
        "Add User": "Добавить пользователя",
        "Reset": "Сбросить",
        "same email":"Учетка с таким email уже есть в базе"
    },
    "addClient": {
        "Login": "Пожалуйста введите логин нового клиента/лида",
        "login_error": "Введите логин!",
        "Add new client": "Добавить нового клиента/лида",
        "Client Added Successfully!": "Клиент/лид успешно добавлен!",
        "We advice you to setup this user's permissions": "Мы рекомендуем вам настроить разрешения этого пользователя",
        "Set Permissions": "Установить разрешения",
        "Go to clients list": "Перейти к списку клиентов/лидов",
        "Close/Add One More Client": "Закрыть/добавить еще одного клиента/лида",
        "Email": "Email",
        "Password": "Пароль",
        "Full Name": "Полное имя",
        "Phone": "Телефон",
        "Active": "Активный",
        "Desk": "Деск",
        "Role": "Роль",
        "Brand": "Бренд",
        "Supervisor": "Руководитель",
        "Add Client": "Добавить клиента/лида",
        "Reset": "Сбросить",
        "same email":"Учетка с таким email уже есть в базе",
        "Campaign": "Кампания",
        "Status": "Статус",
        "Type": "Тип",
        "Assigned To": "Менеджер",
        "Country": "Страна",
        "Address": "Адрес",
    },
    "sideMenu": {
        "Login": "Авторизация",
        "SignUp": "Регистрация",
        "Home": "Главная",
        "Clients": "Клиенты",
        "Users": "Пользователи",
        "Add User": "+ пользователя",
        "Clearing Companies": "Платёжки",
        "Click-2-Call": "Звонилки",
        "Transactions": "Платежи",
        "KYC": "Документы",
        "Affiliates": "Поставщики",
        "Exit": "Выход",
        "Campaigns": "Кампании",
        "Brands": "Бренды",
        "Desks": "Дески",
    },
    "include": {
        "Exit": "Выход",
    },
    "login": {
        "Authorization": "Авторизация",
        "Email": "Email",
        "Password": "Пароль",
        "Remember me": "Запомни меня",
        "Enter": "Войти",
        "login and/or password are wrong": "Логин и/или пароль неверен",
        "вапвап": "вкккккк",

    },
    "theme":{
        "Day":"День",
        "Night":"Ночь",
    },
    "home":{
        "Home page":"Главная страница",
        "descr":"(тут планируется сделать всякую статистику - количество лидов, депов, процент закрытия, суммы и т.п.)",
        "Earned": "Заработано",
        "CBs": "Возвраты",
        // "drgdrtg": "sdrgdrgdrt",
    },
    "users":{
        "Users List": "Список пользователей",
        "Add User": "+ Добавить пользователя",
    },
    "clients":{
        "Clients List": "Список клиентов/лидов",
        "addClient": "+ Добавить клиента/лида"
    },
    "client":{
        "Client Details View": "Просмотр деталей клиента",
        "Clients List": "Список клиентов"
    }

        // "drgdrtg": "sdrgdrgdrt",
        // "drgdrtg": "sdrgdrgdrt",
        // "drgdrtg": "sdrgdrgdrt",
        // "drgdrtg": "sdrgdrgdrt",
        // "drgdrtg": "sdrgdrgdrt",
        // "drgdrtg": "sdrgdrgdrt",


    }
