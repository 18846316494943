export const EN = {
    "":"",
    "addUser": {
        "Login":"Please enter new user login",
        "login_error":"Please input login!",
        "Add new user":"Add new user",
        "User Added Successfully!": "User Added Successfully!",
        "We advice you to setup this user's permissions": "We advice you to setup this user\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to users list": "Go to users list",
        "Close/Add One More User": "Close/Add One More User",


        "Email":"Email",
        "Password":"Password",
        "Full Name":"Full Name",
        // "Last Name":"Last Name",
        "Active":"Active",
        "Desk":"Desk",
        "Role":"Role",
        "Brand":"Brand",
        "Supervisor":"Supervisor",
        "Add User":"Add User",
        "Reset":"Reset",
        "same email":"Account with this email already exists"
    },
    "addClient": {
        "Login":"Please enter new user login",
        "login_error":"Please input login!",
        "Add new client":"Add new client",
        "Client Added Successfully!": "Client Added Successfully!",
        "We advice you to setup this client's permissions": "We advice you to setup this client\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to clients list": "Go to clients list",
        "Close/Add One More Client": "Close/Add One More Client",
        "Email":"Email",
        "Password":"Password",
        "Full Name":"Full Name",
        "Phone":"Phone",
        "Active":"Active",
        "Desk":"Desk",
        "Role":"Role",
        "Brand":"Brand",
        "Supervisor":"Supervisor",
        "Add Client":"Add Client",
        "Reset":"Reset",
        "same email":"Account with this email already exists",
        "Campaign": "Campaign",
        "Status": "Status",
        "Type": "Type",
        "Assigned To": "Assigned To",
        "Country": "Country",
        "Address": "Address",
        // "srdgdsrg": "rgdrdgdrg",
        // "srdgdsrg": "rgdrdgdrg",
        // "srdgdsrg": "rgdrdgdrg",
    },

    "sideMenu": {
        "Login": "Login",
        "SignUp": "SignUp",
        "Home": "Home",
        "Clients": "Clients",
        "Users": "Users",
        "Add User": "Add User",
        "Clearing Companies": "Clearing Companies",
        "Click-2-Call": "Click-2-Call",
        "Transactions": "Transactions",
        "KYC": "KYC",
        "Affiliates": "Affiliates",
        "Campaigns": "Campaigns",
        "Brands": "Brands",
        "Desks": "Desks",
        "вапвап": "вкккккк",
    },
    "include": {
        "Exit": "Exit",
        "вапвап": "вкккккк",
    },

    "login": {
        "Authorization": "Authorization",
        "Email":"Email",
        "Password": "Password",
        "Remember me": "Remember me",
        "Enter": "Enter",
        "login and/or password are wrong": "login and/or password are wrong",
        // "вапвап": "вкккккк",
        // "вапвап": "вкккккк",
        // "вапвап": "вкккккк",

    },

    "theme":{
        "Day":"Day",
        "Night":"Night",
    },
    "home":{
        "Home page":"Home page",
        "descr":"(It is planned to make all sorts of statistics here - number of leads, deposits, percentage of closures, amounts, etc.)",
        "Earned": "Earned",
        "CBs": "Chargebacks",
        // "drgdrtg": "sdrgdrgdrt",
    },
    "users":{
        "Users List": "Users List",
        "Add User": "+ Add user",
    },
    "clients":{
        "Clients List": "Clients List",
        "addClient": "+ Add client"
    },
    "client":{
        "Client Details View": "Client Details View",
        "Clients List": "Clients List"
    }




        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",

}