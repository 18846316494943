
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import css from "./ClientView.module.css";
import {Skeleton} from "antd";
import {setLoading} from "../../redux/clientsReducer";

const ClientView: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'client' });
    const { id } = useParams();

    useEffect(()=>{
        // todo
        props.setLoading(true);
        props.getClientDetails(id)
    },[])

    let loading = true;

    return (
        props.clients.loading ? <>
            <Skeleton></Skeleton>
            </> :
        <>
            <Link to="/clients" className={css.link}>{t('Clients List')}</Link>
            <div></div>
            <h1>{props.clients.selectedClient.fullName}</h1>
            <div>
                <div>{props.clients.selectedClient.email}</div>
                <div>{props.clients.selectedClient.phone}</div>
            </div>
        </>

    );
};

export default ClientView;
