import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {getCookie, setCookie} from "../../common/cookies";
import {getAuthStatus, setAuthStatus} from "../../redux/authReducer";
import {Button} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import css from "../Include/Include.module.css";
import {getAll, setLanguage} from "../../redux/appReducer";


const Include: React.FC = (props:any) => {
    const { t, i18n} = useTranslation('',{ keyPrefix: 'include' });
    const navigate = useNavigate();


    const setLanguage:any = (lang: string) => {
        setCookie('lang',lang,7);
        i18n.changeLanguage(lang);
        // console.log('sl - ' + lang)
        let ru_lang = window.document.getElementById('lang_ru');
        let en_lang = window.document.getElementById('lang_en');

        if(lang === 'ru') {
            props.setLanguage('ru')
            // if(ru_lang) ru_lang.style.color ='#090';
            // if(en_lang) en_lang.style.color ='';
        }
        if(lang === 'en') {
            props.setLanguage('en')
            // if(en_lang) en_lang.style.color ='#090';
            // if(ru_lang) ru_lang.style.color ='';
        }
    }

    const location = useLocation();

    useEffect(()=>{
        props.getAuthStatus();
    },[])

    // this was disabled because it returned to main page every page reload
    useEffect(()=>{
        if(!props.auth.authStatus){
            props.setActiveTab(location.pathname)
            console.log(location.pathname)
            navigate('/login');
        }
    },[props.auth.authStatus])

    useEffect(()=>{

        // props.getDesks();
        // props.getBrands();
        // props.getCountries();
        // props.getCampaigns();
        // props.getStatuses();
        // props.getUsers();
        if(props.auth.authStatus){
            props.getAll();
        }


        let loggedIn = getCookie('loggedIn');
        // if(loggedIn == 1){
        //     props.setAuthStatus(true);
        // }else{
        //     props.setAuthStatus(false);
        // }

        console.log(loggedIn)
        let lang = getCookie('lang')
        i18n.changeLanguage(lang);

        let ru_lang = window.document.getElementById('lang_ru');
        let en_lang = window.document.getElementById('lang_en');

        if(lang === 'ru') {
            props.setLanguage('ru');
            // if(ru_lang) ru_lang.style.color ='#090';
            // if(en_lang) en_lang.style.color ='';
        }
        if(lang === 'en') {
            props.setLanguage('en');
            // if(en_lang) en_lang.style.color ='#090';
            // if(ru_lang) ru_lang.style.color ='';
        }

    },[props.auth.authStatus])

    let exit = () => {
        props.exit();
        navigate('/login');

        // props.history.push('/login');
        // return <Navigate to='/login' />
    }

    return <>
        {!props.auth.authStatus ? null :
        <Button className={css.exit} type="link" onClick={exit}>{t('Exit')}</Button> }

        <div className={css.languages}><span
            id="lang_en" className={`${css.en} ${props.app.language === 'en' ? css.active : ''}`} onClick={()=>setLanguage('en')}>EN</span> / <span
            id="lang_ru" className={`${css.ru} ${props.app.language === 'ru' ? css.active : ''}`} onClick={()=>setLanguage('ru')}>RU</span></div>

    </>
}

export default Include;