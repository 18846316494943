import {API} from "../api/API";
import store from "./store";
import {setClientsAC,setLoading as setClientsLoading} from "./clientsReducer";
import {setDesksAC} from "./desksReducer";
import {setCountriesAC} from "./countriesReducer";
import {setStatusesAC} from "./statusesReducer";
import {setCampaignsAC} from "./campaignsReducer";
import {setBrandsAC} from "./brandsReducer";
import {setUsersAC} from "./usersReducer";
import {setAuthStatusAC, setUserIdAC} from "./authReducer";

// let state = store.getState();

const SET_LANGUAGE = 'crm/app/SET_LANGUAGE';
const SET_ACTIVE_TAB = 'crm/app/SET_ACTIVE_TAB';
const SET_THEME = 'crm/app/SET_THEME';
// const SET_AUTH_DATA = 'crm/auth/SET_AUTH_DATA';

let initialState = {
    language: 'en' as string,
    activeMenuTab: '/' as string,
    theme: 'light' as 'light' | 'dark',
    // login: null as string | null,
    // pass: null as string | null,
    // rememberMe: null as boolean | null,
}

const appReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeMenuTab: action.activeTab
            };
        case SET_THEME:
            return {
                ...state,
                theme: action.theme
            };

        default:
            return state;
    }
}

// let onAuthStatusSet = (store.dispatch,state) => {
//
// }

// ActionCreator

const setLangAC = (language: string) => (
    {
        type: SET_LANGUAGE,
        language: language
    }
)
const setActiveTabAC = (activeTab: string) => (
    {
        type: SET_ACTIVE_TAB,
        activeTab: activeTab
    }
)
const setThemeAC = (theme: string) => (
    {
        type: SET_THEME,
        theme: theme
    }
)
// const setAllAC = (all: object) => (
//     {
//         type: SET_THEME,
//         theme: theme
//     }
// )

// const setAuthDataAC = (login: string, pass: string, rememberMe: boolean) => (
//     {
//         type: SET_AUTH_DATA,
//         login: login,
//         pass: pass,
//         rememberMe: rememberMe,
//     }
// )

// ThunkCreator

export const setLanguage = (language: string) => (dispatch: any) => {
    dispatch(setLangAC(language))
}
export const setTheme = (theme: string) => (dispatch: any) => {
    dispatch(setThemeAC(theme))
}
export const setActiveTab = (activeTab: string) => (dispatch: any) => {
    dispatch(setActiveTabAC(activeTab))
}

export const getAll = ()=> async (dispatch: any) => {
    dispatch(setClientsLoading(true))
    await API.getAll()
        .then((data)=>{
            if(data.status === 'not logged in'){

                dispatch(setAuthStatusAC(false ));
                dispatch(setUserIdAC(0 ));
            }else{
                console.log(data)
                if(data.clients.result === 'ok'){
                    dispatch(setClientsAC(JSON.parse(data.clients.clients)));
                    dispatch(setClientsLoading(false))
                }
                if(data.desks.result === 'ok'){
                    dispatch(setDesksAC(JSON.parse(data.desks.desks)));
                    // dispatch(setClientsLoading(false))
                }
                if(data.countries.result === 'ok'){
                    dispatch(setCountriesAC(JSON.parse(data.countries.countries)));
                    // dispatch(setClientsLoading(false))
                }
                if(data.statuses.result === 'ok'){
                    dispatch(setStatusesAC(JSON.parse(data.statuses.statuses)));
                    // dispatch(setClientsLoading(false))
                }
                if(data.campaigns.result === 'ok'){
                    dispatch(setCampaignsAC(JSON.parse(data.campaigns.campaigns)));
                    // dispatch(setClientsLoading(false))
                }
                if(data.brands.result === 'ok'){
                    dispatch(setBrandsAC(JSON.parse(data.brands.brands)));
                    // dispatch(setClientsLoading(false))
                }
                if(data.users.result === 'ok'){
                    dispatch(setUsersAC(JSON.parse(data.users.users)));
                    // dispatch(setClientsLoading(false))
                }

            }


        })
}

// export const setAuthData = (login: string, pass: string, rememberMe: boolean) => async (dispatch: any) => {
//     dispatch(setAuthDataAC(login, pass, rememberMe))
//     await API.login(login, pass, rememberMe)
//         .then((data)=>{
//             console.log(data);
//             dispatch(setAuthStatusAC(data.status === 'logged in' ));
//         })
//         .catch((error)=>{
//             console.error('error: ' + error)
//         });
//
//     // API - auth
//     // if ok - set auth data null and  authStatus = true
//     // if not ok - show error and  authStatus = true
// }

export default appReducer;