import React from 'react';
import {compose} from "redux";
import { connect } from 'react-redux';
import Include from "./Include";
import {exit, getAuthStatus, setAuthStatus} from "../../redux/authReducer";
import {getAll, setActiveTab, setLanguage} from "../../redux/appReducer";
import { getDesks } from '../../redux/desksReducer';
import { getBrands } from '../../redux/brandsReducer';
import {getCountries} from "../../redux/countriesReducer";
import {getStatuses} from "../../redux/statusesReducer";
import {getCampaigns} from "../../redux/campaignsReducer";
import { getUsers } from '../../redux/usersReducer';
// import Login from "./Login/Login";



const IncludeContainer: React.FC = (props) => (

    <Include {...props} />
);



let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        app: state.app,

    }
};

export default compose(connect(mapStateToProps,{setAuthStatus,exit,setLanguage,getDesks,setActiveTab,getBrands,getStatuses,getCampaigns,getCountries,getUsers,getAll,getAuthStatus}))(IncludeContainer);
