import {API} from "../api/API";

const SET_AUTH_STATUS = 'crm/users/SET_AUTH_STATUS';
const SET_NEW_USER_ADDED_STATUS = 'crm/users/SET_NEW_USER_ADDED_STATUS';
const SET_NEW_USER_ADDED_ERROR = 'crm/users/SET_NEW_USER_ADDED_ERROR';
const SET_NEW_USER_ADDED_ID = 'crm/users/SET_NEW_USER_ADDED_ID';
const SET_LOADING = 'crm/users/SET_LOADING';
const SET_USERS = 'crm/users/SET_USERS';

type User = {
    userId: number | null,
    userName: string | null,
    email: string | null,
    role: string | null,
    desk: string | null,
}
type SelectedUser = {
    userId: number | null,
    userName: string | null,
    email: string | null,
    role: string | null,
    desk: string | null,
    active: boolean | null
}

let initialState = {
    users: []  as Array<User>,
    selectedUser: {
        userId: null,
        userName: null,
        email: null,
        role: null,
        desk: null,
        active: null
    } as SelectedUser,
    pass: null as string | null,
    rememberMe: null as boolean | null,
    newUserAddedStatus: false,
    newUserAddedError: false,
    newUserAddedErrorText: '' as string,
    newUserAddedId: null as null | number,
    loading: false as boolean | null

}

const usersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_NEW_USER_ADDED_STATUS:
            return {
                ...state,
                newUserAddedStatus: action.status
            };
        case SET_NEW_USER_ADDED_ERROR:
            return {
                ...state,
                newUserAddedError: action.error,
                newUserAddedErrorText: action.errorText
            };
        case SET_NEW_USER_ADDED_ID:
            return {
                ...state,
                newUserAddedId: action.newUserAddedId
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_USERS:
            return {
                ...state,
                users: action.users,
            };
        default:
            return state;
    }
}

const setLoadingAC = (loading: boolean) => (
    {
        type: SET_LOADING,
        loading: loading,
    }
)

export const setUsersAC = (users: any) => (
    {
        type: SET_USERS,
        users: users,
    }
)

const setNewUserAddedStatusAC = (status: boolean) => (
    {
        type: SET_NEW_USER_ADDED_STATUS,
        status: status
    }
)

const setNewUserAddedIdAC = (newUserAddedId: number) => (
    {
        type: SET_NEW_USER_ADDED_ID,
        newUserAddedId: newUserAddedId
    }
)

const setNewUserAddedErrorAC = (error: boolean, errorText: string) => (
    {
        type: SET_NEW_USER_ADDED_ERROR,
        error: error,
        errorText: errorText
    }
)

// thunks

export const getUsers = (limit:number,offset:number,orderBy:string,order:string) => async (dispatch: any) => {

    await API.getUsers(limit,offset,orderBy,order)
        .then((data)=>{
            if(data.result === 'ok'){
                dispatch(setUsersAC(JSON.parse(data.users)));
            }else{
                console.log(data);
            }
            dispatch(setLoading(false));

        })
        .catch((error)=>{
            console.error('error: ' + error)
            dispatch(setLoading(false));
        });
}

export const setLoading = (loading: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setLoadingAC(loading))
}

export const setNewUserAddedStatus = (status: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setNewUserAddedStatusAC(status))
}

export const setNewUserData = (email: string | undefined,password: string | undefined,fullName: string | undefined,isActive: boolean | undefined,desk: string | undefined,role: string | undefined,brand: string | undefined,supervisorID: number | undefined) => async (dispatch: any) => {
    // dispatch(setAuthDataAC(login, pass, rememberMe))
    await API.addUser(email,password,fullName,isActive,desk,role,brand,supervisorID)
        .then((data)=>{
            if(data.result === 'ok' && data.userAdded === true){
                dispatch(setNewUserAddedStatusAC(true));
                dispatch(setNewUserAddedIdAC(data.userId));
                dispatch(setNewUserAddedErrorAC(false,''));
                dispatch(setLoadingAC(false));
            }else{
                dispatch(setNewUserAddedErrorAC(true,data.error));
                dispatch(setNewUserAddedStatusAC(false));
                dispatch(setLoadingAC(false));
                // debugger
            }
            console.log(data);
            // dispatch(setAuthStatusAC(data.status === 'logged in' ));
        })
        .catch((error)=>{
            console.error('error: ' + error)
        });

    // API - auth
    // if ok - set auth data null and  authStatus = true
    // if not ok - show error and  authStatus = true
}


//

export default usersReducer;